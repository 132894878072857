import React from 'react'
import PropTypes from 'prop-types'

function TextArea({ id, label, hint, type, autoComplete, optional, rows, className, children })
{
    return (
        <div className={className}>
            <label
                htmlFor={id}
                className={`${label ? 'text-lg text-gray-700' : 'text-md mt-2'} font-normal`}>
                {label || hint}</label>
            <div className="mt-2">
                {children}
                <textarea
                    required={!optional}
                    rows={rows}
                    type={type}
                    name={id}
                    id={id}
                    autoComplete={autoComplete}
                    className="w-full focus:ring-dark-orange focus:border-dark-orange font-extralight text-xl border-0 rounded-md" />
            </div>
        </div>
    )
}

TextArea.defaultProps = {
    type: 'text',
    autoComplete: '',
    optional: false,
    className: '',
    rows: 5,
}

TextArea.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    hint: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    optional: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    rows: PropTypes.number,
}

export default TextArea