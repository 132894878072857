import React from "react"

import CustomButton from "../custom-button"
import Input from "../forms/input"
import TextArea from "../forms/textarea"

const Contact = () => 
{
    return (
        <div className="mt-12" >
            <div className="font-cursive text-4xl text-dark-orange">Contact</div>

            <form
                action="/success/"
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="Contact Form"
                className="space-y-8"
            >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Contact Form" />

                <Input
                    id="name"
                    label="Name"
                    autoComplete="name" />

                <Input
                    id="email"
                    type="email"
                    label="Email"
                    autoComplete="email" />

                <TextArea
                    id="message"
                    label="Message"
                    autoComplete="message" />

                {/* Submit Button */}
                <div className="pt-4 text-center">
                    <CustomButton type="submit" className="mx-auto">
                        Send Message</CustomButton>
                </div>

            </form>
        </div>
    )
}

export default Contact