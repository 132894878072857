import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Intro from "../components/sections/intro"
import Experience from "../components/sections/experience"
import Education from "../components/sections/education"
import Contact from "../components/sections/contact"
import Footer from "../components/sections/footer"

function IndexPage()
{
  return (
    <Layout>
      <Seo title="Welcome | Omana Dhareshini" />
      <Intro />
      <Experience />
      <Education />
      <Contact />
      <Footer />
    </Layout>
  )
}

export default IndexPage