import React from "react"
import Job from "../job"

const Experience = () =>
{
    function showBreak()
    {
        return (
            <hr className="border-t border-dark-blue border-opacity-50 w-full" />
        )
    }

    return (
        <div className="flex flex-col space-y-12 items-center justify-center mt-8">

            <div className="font-cursive text-4xl text-dark-orange">Experience</div>

            <Job
                company="ByteDance (TikTok)"
                start="May 2022"
                role="Sampling Quality Assurance"
                desc="Achieved more than 97% accuracy on a weekly basis (individual), and 98% accuracy month-on-month overall for the team.
                      \n
                      Graduated from the “Train the Trainer” course at ByteDance.
                      \n
                      Handled Malaysia (MY) Live Videos by providing analysis of errors and feedback.
                      \n
                      Worked with cross functional teams to align policy understanding of QA and Moderators.
                      \n
                      Provided support on-call for Policy or Quality related questions on an ad-hoc basis.
                      \n
                      Identified potential errors or different alignment in policies or SOPs and discussed with the operations team on better alignment.
                      \n
                      In charge of handling escalations within team members, assigning tasks on a daily basis and also handling certain project queues in MY Live."
            />

            {showBreak()}

            <Job
                company="TDCX Malaysia"
                start="July 2020"
                end="Apr 2022"
                role="Verified Operations Specialist"
                desc="Took on new projects with Airbnb with fast moving targets and tight turnaround times on a regular and repeated basis.
                      \n
                      Provided structured feedback and recommendations for process improvements from time to time.
                      \n
                      Carried out project employee engagement as their President."
            />

            {showBreak()}

            <Job
                company="Lenovo (Malaysia), Scicom (MSC) Bhd"
                start="Apr 2016"
                end="Sept 2019"
                role="Customer Care"
                desc="Responded and negotiated a resolution for Lenovo customers who have brought forward a complaint about Lenovo services.
                      \n
                      Responsible for customer satisfaction issues while maintaining and enhancing positive client relationships.
                      \n
                      Coordinate with cross-functional logistics teams in China & India for replacement, return and refund process.
                      \n
                      Proactive in problem identification and resolution along different integrated systems (CRM/SAP/iBase).
                      \n
                      Drove E2E improvement of processes with focus on SLAs, quality and cost-saving."
            />

            {showBreak()}

            <Job
                company="NOL Global Service Centre Sdn Bhd"
                start="Mar 2015"
                end="Feb 2016"
                role="Outbound Documentation Officer"
                desc="Processed documents for shipping containers under ​American President Lines​ to be cleared by customs throughout Asia Pacific."
            />

            {showBreak()}

            <Job
                company="Tesco Stores (Malaysia)"
                start="Jan 2014"
                end="Nov 2014"
                role="Local Marketing Executive"
                desc="Carried out new store launches, defense campaigns, advertising, and organized events.
                      \n
                      Wrote materials for articles and newsletters, and worked with vendors and event agencies for projects and campaigns such as launch parties and marketing events.
                      \n
                      Organized media events and contributed to the overall communication strategy. Worked closely with the CSR team, for their campaigns.
                      \n
                      Liaised with media representatives on a frequent basis for news content."
            />

        </div>
    )
}

export default Experience