import React from "react"
import PropTypes from "prop-types"

const Job = ({ company, start, end, role, desc }) =>
{
    const companySplit = company.split('\\n')
    const splitDesc = desc.split('\\n')
    const linkDelimiter = ':link:'

    return (
        <div className="flex flex-col space-y-4">
            {companySplit.map((item, idx) => (
                <div key={idx} className="font-semibold text-2xl">{item}</div>
            ))}
            <p className="font-light text-xl">{[start, end].filter(x => x.length).join(` - `)} | {role}</p>
            <ol>
                {desc && splitDesc.map((item, idx) =>
                {
                    const linkSplit = item.split(linkDelimiter)
                    return (
                        <li key={idx} className="font-extralight text-xl list-disc text-justify mt-4" >
                            {
                                linkSplit.map((linkItem, linkIdx) =>
                                {
                                    if (linkItem === linkDelimiter)
                                    {
                                        return <React.Fragment key={linkIdx}></React.Fragment>
                                    }
                                    // hack hack until I find more use cases
                                    if (linkItem === 'www.lovepawshomestay.com')
                                    {
                                        return <a href='https://lovepawshomestay.com' target='_blank' rel='noopener noreferrer' className='underline font-semibold text-dark-orange'>www.lovepawshomestay.com</a>
                                    }
                                    return (
                                        <span key={linkIdx}>{linkItem}</span>
                                    )
                                })
                            }
                        </li>
                    )
                })}
            </ol>
        </div >
    )
}

Job.defaultProps = {
    end: ``
}

Job.propTypes = {
    company: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
    role: PropTypes.string.isRequired,
    desc: PropTypes.string
}

export default Job