import React from "react"

const Education = () =>
{
    return (
        <div className="flex flex-col space-y-2 mt-12">
            <div className="font-cursive text-4xl text-dark-orange">Education</div>
            <div className="font-semibold text-2xl">Inti International University</div>
            <p className="font-extralight text-xl">Bachelors of Mass Communication (Hons)</p>
            <p className="font-extralight text-xl">Graduated December 2014</p>
        </div>
    )
}

export default Education