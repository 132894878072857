import React from "react"
import PropTypes from "prop-types"

const SmallYellowShape = ({ style, className }) =>
{
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48.09 49.76">
                <path d="M24,.6C17.74-.78,10.63.11,5.92,4.45,1,9-.29,16.21.05,22.85c.33,6.36,2,12.81,5.94,17.84a24,24,0,0,0,22.81,8.7A24,24,0,0,0,47,33.14C52,16.72,38.93,3.89,24,.6Z" />
            </svg>
        </div>
    )
}

SmallYellowShape.defaultProps = {
    style: {},
    className: ""
}

SmallYellowShape.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string
}

export default SmallYellowShape