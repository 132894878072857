import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import DottedShape from "../images/DottedShape"
import SmallYellowShape from "../images/SmallYellowShape"
import BigGreenShape from "../images/BigGreenShape"

const Intro = () => 
{
    return (
        <>
            <div className="relative">
                <div className="absolute inset-x-0 top-12 z-10 font-cursive text-3xl">Hello, I&apos;m</div>
                <div className="absolute inset-x-0 top-24 z-10 font-cursive text-6xl"><span className="text-dark-orange">Omana</span> Dhareshini</div>
                <div className="absolute inset-x-0 top-60 z-10">
                    <StaticImage src="../../images/profile/omana.png" width="256" alt="Omana's Profile Picture" />
                </div>
                <BigGreenShape className="absolute -top-2 -left-36 z-0 text-light-green w-96" />
                <SmallYellowShape className="absolute top-40 right-40 z-0 text-dark-yellow w-20" />
                <DottedShape className="absolute top-0 -right-10 z-0 text-dark-yellow w-32" />
                <DottedShape className="absolute top-96 inset-x-0 left-0 sm:left-28 z-20 text-dark-yellow w-40" />

                <div className="h-144">&nbsp;</div>
            </div>

            {/* <div className="flex flex-row items-center justify-center space-x-8">
                <a href="https://www.facebook.com/omana.dhareshini" aria-label="Facebook"><StaticImage src="../../images/icons/fb-icon.png" height="48" alt="Facebook Icon" /></a>
                <a href="https://www.instagram.com/omz_dharshy/" aria-label="Instagram"><StaticImage src="../../images/icons/insta-icon.png" height="48" alt="Instagram Icon" /></a>
            </div> */}

            <p className="font-extralight text-2xl pt-8">Welcome to my online résumé. I’m a creative person providing forward-thinking out-of-the-box solutions for your business challenges.</p>

            <div className="py-8">
                <a href="/files/Omana-Dhareshini-Resume.pdf" className="bg-gradient-to-tr from-dark-yellow to-dark-yellow hover:to-dark-orange hover:text-white px-8 py-5 w-full rounded-xl font-bold text-xl" >
                    Download My Résumé (pdf)</a>
            </div>
        </>
    )
}

export default Intro