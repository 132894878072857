import React from "react"
import PropTypes from "prop-types"

const BigGreenShape = ({ style, className }) =>
{
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 212.92 219.01">
                <path d="M204.05,113.05c-11.8-32.83-28.69-64.94-55.53-87.24S83.37-7.27,50.86,5.38A79.64,79.64,0,0,0,8.16,45.25C-4,71-1.2,101.59,7.48,128.73c7,21.86,17.93,42.94,34.76,58.54,18.26,16.93,42.57,26.46,67.28,29.57,23.49,2.95,63,5.59,82.15-11.43C219.84,180.42,215.62,145.24,204.05,113.05Z" />
            </svg>
        </div>
    )
}

BigGreenShape.defaultProps = {
    style: {},
    className: ""
}

BigGreenShape.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string
}

export default BigGreenShape