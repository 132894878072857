import React from 'react'
import PropTypes from 'prop-types'

function CustomButton({ children, className, style, onClick, type })
{
    return (
        <button type={type} onClick={onClick} style={style} className={`${className} flex flex-row items-center justify-center space-x-2 font-medium text-lg px-10 py-5 bg-gradient-to-tr from-dark-yellow to-dark-yellow hover:to-dark-orange rounded-xl hover:text-white focus:outline-none`}>
            {children}
        </button>
    )
}

CustomButton.defaultProps = {
    onClick: () => { },
    style: {},
    className: '',
    type: 'button',
}

CustomButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    type: PropTypes.string,
}

export default CustomButton