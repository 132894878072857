import React from "react"
import PropTypes from "prop-types"

const DottedShape = ({ style, className }) =>
{
    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.79 122.91">
                <defs>
                    <pattern fill="currentColor" id="Dotted_Shape_Yellow" data-name="Dotted Shape Yellow" width="13.5" height="9" patternUnits="userSpaceOnUse" viewBox="0 0 13.5 9">
                        <rect style={{ fill: "none" }} width="13.5" height="9" />
                        <path d="M13.5,7.3a1.4,1.4,0,0,0-1.27.27,1.6,1.6,0,0,0-.42,1.29,2.31,2.31,0,0,0,.42,1.25,1.65,1.65,0,0,0,2.88-.53A1.86,1.86,0,0,0,13.5,7.3Z" />
                        <path d="M0,7.3a1.4,1.4,0,0,0-1.27.27,1.6,1.6,0,0,0-.42,1.29,2.31,2.31,0,0,0,.42,1.25,1.67,1.67,0,0,0,1.6.61A1.67,1.67,0,0,0,1.61,9.58,1.86,1.86,0,0,0,0,7.3Z" />
                        <path d="M13.5-1.7a1.4,1.4,0,0,0-1.27.27,1.6,1.6,0,0,0-.42,1.29,2.31,2.31,0,0,0,.42,1.25,1.67,1.67,0,0,0,1.6.61A1.67,1.67,0,0,0,15.11.58,1.86,1.86,0,0,0,13.5-1.7Z" />
                        <path d="M6.75,2.8a1.4,1.4,0,0,0-1.27.27,1.6,1.6,0,0,0-.42,1.29,2.31,2.31,0,0,0,.42,1.25,1.65,1.65,0,0,0,2.88-.53A1.86,1.86,0,0,0,6.75,2.8Z" />
                        <path d="M0-1.7a1.4,1.4,0,0,0-1.27.27A1.6,1.6,0,0,0-1.69-.14a2.31,2.31,0,0,0,.42,1.25,1.67,1.67,0,0,0,1.6.61A1.67,1.67,0,0,0,1.61.58,1.86,1.86,0,0,0,0-1.7Z" />
                    </pattern>
                </defs>
                <path style={{ fill: `url(#Dotted_Shape_Yellow)` }} d="M59.25,1.48C43.81-1.93,26.25.26,14.63,11,2.57,22.16-.73,40,.13,56.44c.82,15.71,5,31.65,14.67,44.08,13,16.7,35.55,25.31,56.33,21.49s38.83-19.94,45-40.15C128.45,41.3,96.16,9.62,59.25,1.48Z" />
            </svg>
        </div>
    )
}

DottedShape.defaultProps = {
    style: {},
    className: ""
}

DottedShape.propTypes = {
    style: PropTypes.object,
    width: PropTypes.number,
    className: PropTypes.string
}

export default DottedShape