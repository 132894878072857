import React from 'react'
import PropTypes from 'prop-types'

function Input({ id, label, hint, type, autoComplete, optional, className, children })
{
    return (
        <div className={className}>
            <label
                htmlFor={id}
                className={`${label ? 'text-lg text-gray-700' : 'text-md mt-2'} font-normal`}>
                {label || hint}</label>
            <div className="mt-2">
                {children}
                <input
                    required={!optional}
                    type={type}
                    name={id}
                    id={id}
                    autoComplete={autoComplete}
                    className="w-full focus:ring-dark-orange focus:border-dark-orange font-extralight text-xl border-0 rounded-md" />
            </div>
        </div>
    )
}

Input.defaultProps = {
    type: 'text',
    autoComplete: '',
    optional: false,
    className: '',
}

Input.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    hint: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    optional: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
}

export default Input