import React from "react"

const Footer = () => 
{
    return (
        <footer className="mt-40 mb-20">
            <p className="font-extralight text-xl">&copy; {new Date().getFullYear()} Omana Dhareshini</p>
            <p className="font-extralight text-lg">Thank you for visiting 🙂</p>
        </footer>
    )
}

export default Footer